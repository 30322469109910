import React from 'react'
import { Helmet } from 'react-helmet'

import 'twin.macro'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Trade Calculator | 404</title>
    </Helmet>
    <div tw="container">
      <div tw="flex flex-col items-center py-8">
        <h1 tw="font-heading text-2xl">404</h1>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
